import Template from '../Template'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import React from 'react'
import { Helmet } from 'react-helmet'

const PageEmailSignup = () => (
  <Template>
    <Helmet>
      <title>Thank you!</title>
    </Helmet>
    <div className={style.pageWrapper}>
      <div className={style.hero}>
        <h1>Thank you!</h1>
        <p>You have successfully signed up for the waitlist</p>
        <Link className={style.button} to='/'>
          Back to the homepage
        </Link>
      </div>
      <div className={style.resultBox}>
        <h3>Launching in spring 2024</h3>
        <p>We're hard at work preparing for our public release.</p>
        <p>
          If you have any questions about doggos.com, or would like to apply to
          become a dog sitter before our public launch, please reach out
          directly <a href='mailto:team@doggos.com'> team@doggos.com</a>!
        </p>
        <p>
          If you are a writer or have press inquiries, please contact{' '}
          <a href='mailto:press@doggos.com'> press@doggos.com</a>
        </p>
        <p>
          For all other inquiries{' '}
          <a href='mailto:hello@doggos.com'> hello@doggos.com</a>
        </p>
        <p>
          Thank you so much for your support. We look forward to welcoming you
          and your dog to the platform very soon.
        </p>
      </div>
    </div>
  </Template>
)

export default PageEmailSignup
